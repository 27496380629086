import styled from '@emotion/styled'
import { mediaMax, sectionSubtitle } from '@/styles/mixins'

export const Wrapper = styled.div`
  width: 100%;
  max-width: 120rem;
  margin: 0 auto;
  padding: 0 15px;
  position: relative;
  z-index: 3;
`

export const Content = styled.div`
  font-family: ${({ theme }) => theme.fonts.handwriting};
  color: ${({ theme }) => theme.colors.green};
  font-size: 6rem;
  line-height: 130%;
  text-align: center;

  ${mediaMax.xs} {
    font-size: 3rem;
  }
`

export const Inner = styled.blockquote`
  display: inline;
`

export const Author = styled.p`
  ${sectionSubtitle()}
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`

export const Subtitle = styled.span`
  ${sectionSubtitle()}
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
`

export const Marks = styled.span`
  font-size: 6rem;
  font-family: ${({ theme }) => theme.fonts.handwriting};
  margin: 0 0.8rem;
  ${mediaMax.xs} {
    font-size: 5rem;
  }
`

export const Container = styled.div`
  padding: ${({ theme }) => theme.spacing.large} 0;
  background-image: ${({ img }) => img ? `linear-gradient(to top, #000000c4, transparent), url(${img})` : ''};


  ${mediaMax.xs} {
    padding: 2rem 0;
  }

  &.hasImage {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    // margin: 50px 0;
    padding: 10vh 10%;
    &::before{
      content:'';
      position: absolute;
      height: 100%;
      width: 100%;
      display: inline-block;
      // background-color: #00000030;
      z-index: 2;
    }

    ${mediaMax.xs} {
      padding: 6vh 2.5vw;
    }

    ${Wrapper} {
      width: 100%;
      max-width: 100%;
    }

    ${Author}{
      color: ${({ theme }) => theme.colors.white};
    }

    ${Marks},
    ${Content}{
      font-family: ${({ theme }) => theme.fonts.serif};
      color: ${({ theme }) => theme.colors.white};
      font-size: 4.5rem;
      line-height: 6rem;

      ${mediaMax.xs} {
        font-size: 2.5rem;
        line-height: 3.5rem;
      }
    }
  }
`
